import {Outlet} from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const ErrorsLayout = () => {

  return (
    <>
       <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center bg-primary flex-column-fluid  h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid  w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className=' d-flex flex-start flex-lg-row-fluid p-15'>
          <div className="w-md-600px  p-15">
              <Outlet />
          </div>
          <div style={{top: 80, right: '12%'}} className='mx-auto position-absolute '>
             <SVG src={toAbsoluteUrl('/media/svg/login/texture-circle.svg')} />
          </div>
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      <div className='position-absolute bottom-0 start-0 '>
        <SVG src={toAbsoluteUrl('/media/svg/login/dots.svg')} />
      </div>
    </div>
    </>
  )
}

export {ErrorsLayout}
