import { InformationBlockEvent } from "../../../../commons/interfaces/event/informationBlockEvent.interface";

export const defaultInformationBlockForm: InformationBlockEvent = {
  title: '',
  subtitle: '',
  content: '',
  alignment: 'left',
  url: '',
  management_file_public: [],
}

export const informationBlockFormRules = {
  title: { required: true },
  subtitle: { required: false },
  alignment: { required: true },
  management_file_public: { required: true }
}