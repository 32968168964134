import {memo} from 'react'
import {EventGalleryForm} from '../../interfaces/event/eventGallery.interface'
import FormElemenWrapper from '../formElemenWrapper/FormElemenWrapper'
import InputUploadFiles from '../../../_metronic/layout/components/input/InputUploadFiles'
import {FileUploaded} from '../../interfaces/managementFile/managementFileCreate'

/**
 * Represents the props for the Form component.
 */
interface FormProps {
  /**
   * The element to be displayed in the form.
   */
  element: EventGalleryForm

  /**
   * Indicates whether the form is disabled or not.
   */
  formDisabled: boolean
  errorsForm:any
  /**
   * The callback function to be called when a change occurs in the form.
   * @param code - The code of the form.
   * @param field - The field that has changed.
   * @param value - The new value of the field.
   */
  onChange: (code: string, field: string, value: string | FileUploaded[]) => void
}
interface FormProps {
  element: EventGalleryForm
  formDisabled: boolean
  onChange: (code: string, field: string, value: string | FileUploaded[]) => void
}

/**
 * Renders a form for a gallery.
 * The GalleryForm component is a functional component.
 * Is the base component for the gallery form that will be used in the DndForm.
 * @param element - The gallery element.
 * @param formDisabled - Indicates if the form is disabled.
 * @param onChange - The function to handle form field changes.
 */
const GalleryForm: React.FC<FormProps> = memo(({element, formDisabled, onChange,errorsForm}: FormProps) => {
  // The element is destructured to get the values of the gallery.
  const {code, name, content, management_file_public} = element

  const showError = (error: string, text: string) => {
    const showError = errorsForm.includes(error)
    if (showError) {
      return text
    }
    return;
  }
  return (
    <>
      <div className='row p-4'>
        <div className='col-12'>
          <FormElemenWrapper label=''>
            <InputUploadFiles
              required
              id={`image-${code}`}
              formDisabled={formDisabled}
              file={management_file_public ? management_file_public[0] : null}
              handleFunction={(value: FileUploaded | null) =>
                onChange(code, 'management_file_public', value ? [value] : [])
              }
            />
            <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {showError('management_file_public', 'Imagen es requerido')}
            </span>
          </div>
          </FormElemenWrapper>
        </div>
        <FormElemenWrapper label='Nombre de la imagen' required>
          <input
            id={`name-${code}`}
            className='form-control input-document'
            type='text'
            value={name || ''}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'name', e.target.value)}
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Texto descriptivo de la imagen'>
          <textarea
            id={`content-${code}`}
            className='form-control input-document'
            value={content}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'content', e.target.value)}
          />
        </FormElemenWrapper>
      </div>
    </>
  )
})

export default GalleryForm
