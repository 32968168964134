import { IEventActivityPrepared } from "../../../../commons/interfaces/event/eventSchedule.interface";

export const defaultEventActivityForm: IEventActivityPrepared = {
  name: '',
  content: '',
  management_file_public: [],
  date_init: '',
  date_end: '',
  hour_init: '00:00',
  hour_end: '23:59'
}

export const activityFormRules = {
  name: { required: true },
  management_file_public: { required: false },
  date_init: { required: true },
  date_end: { required: true },
  hour_init: { required: true },
  hour_end: { required: true },
}