// useAuth.js
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useMutation } from 'react-query'
import authService from '../../../../services/auth/auth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { changePassword, login, logout } from '../../../../store/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import passwordService from '../../../../services/password'
import { notify } from '../../../../store/password/passwordSlice'
import { isAxiosError } from 'axios'

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Usuario requerido.'),
  password: Yup.string().required('Contraseña requerida.'),
  recaptcha: Yup.string().required('¡Ups! Parece que olvidaste seleccionar el reCaptcha. Inténtalo de nuevo.'),
})
const firstPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Contraseña requerida.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,140})/,
      'La contraseña no cumple con los requisitos.'
    ),
  password_confirmation: Yup.string()
    .required('Confirmar contraseña requerida.')
    .test('passwords-match', 'Las contraseñas no coinciden.', function (value) {
      return this.parent.password === value
    }),
})

const recoveryPasswordSchema = Yup.object().shape({
  username: Yup.string().required('Usuario requerido.'),
})

const useAuth = () => {
  const { isAuthenticated, userLogin } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loginMutation = useMutation({
    mutationFn: (credentials: { username: string; password: string, recaptcha:string}) =>
      authService.login(credentials, credentials.recaptcha),
    onSuccess: (loginSuccess) => {
      localStorage.setItem('stateReload', 'start')
      dispatch(login(loginSuccess.data))
    },
    onError: () => {
      loginForm.setError('password', {
        message: '¡Ups! Las credenciales ingresadas son incorrectas. Inténtalo nuevamente.',
      })
    },
  })

  const firstPasswordMutation = useMutation({
    mutationFn: (credentials: { password: string; password_confirmation: string }) =>
      authService.changePassword(credentials),
    onSuccess: (loginSuccess) => {
      dispatch(changePassword(loginSuccess.data))
    },
  })

  const logoutMutation = useMutation({
    mutationFn: () => authService.logout(),
    onSuccess: () => {
      dispatch(logout())
      navigate('/auth')
    },
  })

  const recoveryPasswordMutation = useMutation({
    mutationFn: (credentials: { username: string }) => passwordService.notify(credentials),
    onSuccess: (response) => {
      dispatch(notify(response.data))
    },
    onError: () => {
      recoveryPasswordForm.setError('username', {
        message:
          '¡Ups! Parece que hay un problema con el usuario. Por favor, póngase en contacto con el administrador.',
      })
    },
  })

  const validateTokenMutation = useMutation({
    mutationFn: (credentials: { username: string; token: string }) =>
      passwordService.validateToken(credentials),
    onError: (err) => {
      if (isAxiosError(err)) {
        if (err.response!.status === 401) {
          navigate('/auth', { state: 'expired' })
        }
      }
    },
  })


  const verifyTokenMutation = useMutation(() => authService.verifyToken(), {
    onSuccess: () => {
      refreshTokenMutation.mutateAsync();
    },
    onError: (err) => {
      dispatch(logout())
      navigate('/auth')
    },
  })

  const refreshTokenMutation = useMutation(() => authService.refreshToken(), {
    onSuccess: (refreshSuccess) => {
      dispatch(login(refreshSuccess.data))
    },
    onError: (err) => {
      dispatch(logout())
      navigate('/auth')
    },
  })

  const resetPasswordMutation = useMutation({
    mutationFn: (credentials: {
      password: string
      password_confirmation: string
      username: string
      token: string
    }) => passwordService.resetPassword(credentials),
  })

  const verifyError403 = (err: unknown) => {
    if (isAxiosError(err)) {
      if (err.response!.status === 403)
        navigate('/error/403', { state: 'expired' })
      else {
        dispatch(logout())
        navigate('/auth')
      }
    }
  }

  const firstPasswordForm = useForm({
    resolver: yupResolver(firstPasswordSchema),
  })

  const loginForm = useForm({
    resolver: yupResolver(loginSchema),
  })

  const recoveryPasswordForm = useForm({
    resolver: yupResolver(recoveryPasswordSchema),
  })

  const changePasswordForm = useForm({
    resolver: yupResolver(firstPasswordSchema),
  })

  return {
    isAuthenticated,
    userLogin,
    verifyError403,
    mutations: {
      loginMutation,
      firstPasswordMutation,
      logoutMutation,
      recoveryPasswordMutation,
      validateTokenMutation,
      resetPasswordMutation,
      verifyTokenMutation
    },
    forms: { loginForm, firstPasswordForm, recoveryPasswordForm, changePasswordForm },
  }
}

export default useAuth
