import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import SVG from 'react-inlinesvg'
import {useMutation, useQueryClient} from 'react-query'
import {MainTitle} from '../../../../commons/components/Title/MainTitle'
import {useNavigate} from 'react-router-dom'
import {FormProvider, useForm} from 'react-hook-form'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {GeneralInformation} from './components/tabs/GeneralInformation'
import {AdditionalInformation} from './components/tabs/AdditionalInformation'
import {ModalSuccess} from './components/modals/ModalSuccess'
import eventService from '../../../../services/event'
import {ModalConfirmation} from '../../../../commons/components/modals/ModalConfirmation'
import {IconCancelCircle, IconCheckCircle} from '../../../../commons/icons'
import {ModalAlert} from '../../../../commons/components/modals/ModalAlert'
import {toast} from 'react-toastify'
import {useErrorHandling} from '../../../../commons/hooks/useErrorHandling'
import {ModalErrors} from '../../../../commons/components/modals/ModalErrors'

// Creation of the schema for the form
const createEventSchema = Yup.object().shape({
  name: Yup.string().max(140, 'Máximo 140 caracteres').required('Nombre del evento es requerido'),
  code: Yup.string()
    .max(140, 'Máximo 140 caracteres')
    .required('Codigo del evento es requerido')
    .test('no-espacios', 'El código no debe contener espacios en blanco', (value) => {
      if (value && /\s/.test(value)) {
        return false
      }
      return true
    }),
  code_as400: Yup.string().max(140, 'Máximo 140 caracteres'),
  start_date: Yup.string(),
  end_date: Yup.string(),
  event_society_id: Yup.string().required('Sociedad del evento es requerido'),
  status: Yup.string().required('Estado del evento es requerido'),
  email: Yup.string()
    .required('Email es requerido')
    .email('Formato de correo inválido')
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Máximo 140 caracteres'),
  url: Yup.string().required(),
  lat: Yup.number().required('Latitud es requerida'),
  lng: Yup.number().required('Longitud es requerida'),
  address: Yup.string(),
  reference: Yup.string(),
})

export const CreateEvent = () => {
  // Query client to invalidate the cache
  const queryClient = useQueryClient()
  const {errorType, handleApiError, clearError} = useErrorHandling()
  const navigation = useNavigate()

  // States to control the modals
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [showModalCancel, setShowModalCancel] = useState(false)
  const [showModalAlert, setShowModalAlert] = useState(false)

  const methods = useForm({
    resolver: yupResolver(createEventSchema),
  })

  const {handleSubmit, reset} = methods

  // Mutation to create a new event
  const userMutation = useMutation({
    mutationFn: eventService.create,
    onSuccess: () => {
      toast.success('El evento fue creado con éxito.', {
        position: 'bottom-right',
        autoClose: 5000, // Tiempo de cierre automático en milisegundos
        hideProgressBar: true, // Mostrar barra de progreso
        closeOnClick: true, // Cerrar al hacer clic
        draggable: true, // Arrastrable
        progress: undefined, // Personalizar la barra de progreso
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      setShowModalSuccess(false)
      queryClient.invalidateQueries('eventList')
      navigation('/eventos/listar')
    },
    onError: (e: any) => {
      handleApiError(e)
      //setShowModalError(true)
    },
  })

  // Function to go back
  const onSubmitBack = () => {
    navigation(-1)
    reset()
  }

  // Function to submit the form
  const onSubmit = (data: any) => {
    // Extract the data to send
    const {email, url, lat, lng, address, reference,phone, ...rest} = data
    // Create the new object to send
    const newData = {...rest}
    // Add the location data
    newData.content_event_location = {
      address,
      reference,
      phone,
      coordinates: {lat: lat, lng: lng},
      contact: email,
      url: url,
    }
    // Add the opening hours
    newData.content_event_opening = []
    data.content_event_opening.forEach((item: any) => {
      const {date_label, ...rest} = item
      newData.content_event_opening.push(rest)
    })
    // Execute the mutation
    userMutation.mutate(newData)
  }

  return (
    <div className=''>
      <MainTitle disabled title='Crear evento' icon />
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x ms-3  mt-5 mb-7 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
            Información general
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
            Información adicional
          </a>
        </li>
      </ul>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
              <GeneralInformation />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
              <AdditionalInformation />
            </div>
          </div>

          <div className='container mt-15 mb-5'>
            <div className='row justify-content-center'>
              <div className='col-auto'>
                <button
                  type='button'
                  onClick={() => setShowModalCancel(true)}
                  className='btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3'
                >
                  <SVG src={toAbsoluteUrl('/media/svg/login/exit.svg')} />
                  Cancelar
                </button>
              </div>
              <div className='col-auto'>
                <button
                  onClick={() => {
                    // Validate the form
                    const endDate = methods.getValues().end_date
                    const startDate = methods.getValues().start_date
                    const lat = methods.getValues().lat
                    const lng = methods.getValues().lng
                    const data = methods.getValues()
                    // Remove the fields that are not needed
                    delete data.code_as400
                    delete data.address
                    delete data.reference

                    const isValid = Object.values(data)
                      .map((e) => {
                        if (
                          e === null ||
                          e === undefined ||
                          e === '' ||
                          (Array.isArray(e) && e.length === 0)
                        )
                          return false
                        return true
                      })
                      .includes(false)

                    if (isValid || !endDate || !startDate || !lat || !lng) {
                      setShowModalAlert(true)
                    } else {
                      setShowModalSuccess(true)
                    }
                  }}
                  type='button'
                  disabled={userMutation.isLoading}
                  className='btn btn-primary me-2  d-flex align-items-center gap-3'
                >
                  {!userMutation.isLoading && (
                    <div className='d-flex gap-2 align-items-center justify-content-center'>
                      <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} />
                      <span className='indicator-label'>Crear Evento</span>
                    </div>
                  )}
                  {userMutation.isLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Cargando...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>

        <ModalSuccess
          onSubmit={handleSubmit(onSubmit)}
          show={showModalSuccess}
          onHide={() => setShowModalSuccess(false)}
        />
        <ModalConfirmation
          icon={<IconCancelCircle />}
          title='¿Estás seguro que deseas cancelar la creación de un evento?'
          textButton='Sí, cancelar'
          textButtonCancel='No, continuar'
          show={showModalCancel}
          onHide={() => setShowModalCancel(false)}
          onClick={onSubmitBack}
        />
        <ModalAlert show={showModalAlert} onHide={() => setShowModalAlert(false)} />
        {errorType && <ModalErrors errorType={errorType} onClose={clearError} />}
      </FormProvider>
    </div>
  )
}
