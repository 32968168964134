import {useEffect} from 'react'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {IconSearch2} from '../../../../../commons/icons'
import GiftStatusBadges from '../../../../../commons/components/badges/GiftStatusBadges'
import { Gift } from '../../../../../commons/interfaces/gifts/giftList.interface'

interface IProps {
  data: Gift[] | undefined
  refetched: boolean
  isNotResult?: boolean
  isNotResultFilter?: boolean
}

export const TableGifts = ({data, refetched, isNotResult, isNotResultFilter}: IProps) => {

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [refetched])

  return (
    <div className='table-responsive rounded '>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead className='bg-head-table '>
          <tr className='fw-medium text-label'>
            <th className='min-w-25px'> </th>
            <th className='min-w-100px'>Tipo de doc.</th>
            <th className='min-w-100px'># de doc.</th>
            <th className='min-w-150px'>Cód. del regalo</th>
            <th className='min-w-175px'>Cant. lim. para canje</th>
            <th className='min-w-150px'>ID del evento</th>
            <th className='w-175px'>Estado</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data?.map((item) => {
            return (
              <tr key={item.id}>
                <td></td>
                <td>
                  <span className='text-muted fw-semibold d-block fs-7'>
                    {item?.generated_order?.purchase_order?.entity_customer?.document_type}
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold d-block fs-7'>
                    <span className='text-muted me-2 fs-7 fw-semibold'>
                      {item?.generated_order?.purchase_order?.entity_customer?.document_number}
                    </span>
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item?.code}
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item?.quantity}
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item?.content_event.code}
                  </span>
                </td>
                <td className='d-flex'>
                  <GiftStatusBadges status={item.state} />
                </td>
              </tr>
            )
          })}
          {isNotResult && (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center mt-5'>
                  <IconSearch2 />
                </div>
                <div className='d-flex flex-column justify-content-center mt-5 text-center'>
                  {isNotResultFilter && (
                    <p style={{fontWeight: 300}} className=' text-label mb-2'>
                      No se han encontrado resultados en esta búsqueda.
                    </p>
                  )}
                  <p className='text-label tw-green-700'>
                    {isNotResultFilter
                      ? 'Por favor, intenta otra vez.'
                      : 'No se han encontrado resultados en esta búsqueda.'}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}