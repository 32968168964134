import { useLocation, useNavigate } from 'react-router-dom'
import { IconArrowDown } from '../../icons'
import React, { useEffect, useState } from 'react';

interface IProps {
    title: string;
    icon?: boolean;
    disabled?: boolean;
    editTitle?: string;
    onRouteChange?: (route: string) => void;
}

export const MainTitle = ({ title, icon, disabled, editTitle, onRouteChange }: IProps) => {
    const navigation = useNavigate()
    const location = useLocation();
    const [previousRoute, setPreviousRoute] = useState<string>("");
    useEffect(() => {
        if (onRouteChange) {
            onRouteChange(location.pathname);
        }
    }, [location, onRouteChange]);

    useEffect(() => {
        setPreviousRoute(location.pathname);
    }, [location]);

    const handleBackClick = () => {
        localStorage.setItem("previosPage",previousRoute)
        navigation(-1);
    };

    return (
        <div className="d-flex gap-5 align-items-center">
            {icon && (
                <div className="p-2 cursor-pointer fa-rotate-270 "
                    onClick={handleBackClick}
                >
                    <IconArrowDown stroke='#00A160' width={25} height={25} />
                </div>)}
            <div className="title">
                {disabled && title}
                {!disabled && editTitle}
            </div>
        </div >

    )
}
