import {memo, useContext} from 'react';
import {IEventActivityForm} from '../../interfaces/event/eventSchedule.interface';
import FormElemenWrapper from '../formElemenWrapper/FormElemenWrapper';
import InputUploadFiles from '../../../_metronic/layout/components/input/InputUploadFiles';
import {FileUploaded} from '../../interfaces/managementFile/managementFileCreate';
import {DatePickerSimple} from '../datePicker/DatePickerSimple';
import {TimePicker} from '../datePicker/TimePicker';
import {eventContext} from '../../../app/modules/event/pages/EventSchedule';

// The ActivityForm component receives the element, formDisabled, and onChange properties.
/**
 * Props for the ActivityForm component.
 */
interface FormProps {
  /**
   * The element representing the form data.
   */
  element: IEventActivityForm

  /**
   * Indicates whether the form is disabled or not.
   */
  formDisabled: boolean
  errorsForm:any
  /**
   * Callback function triggered when a change occurs in the form fields.
   *
   * @param code - The code of the form.
   * @param field - The field that has changed.
   * @param value - The new value of the field.
   */
  onChange: (code: string, field: string, value: string | FileUploaded[]) => void
}
interface FormProps {
  element: IEventActivityForm
  formDisabled: boolean
  onChange: (code: string, field: string, value: string | FileUploaded[]) => void
}
/**
 * Renders a form for an information block.
 * The ActivityForm component is a functional component.
 * Is the base component for the activity form that will be used in the DndForm.
 * @param element - The information block element.
 * @param formDisabled - Indicates if the form is disabled.
 * @param onChange - The function to handle form field changes.
 * @returns The ActivityForm component.
 */
const ActivityForm: React.FC<FormProps> = memo(({element, formDisabled, onChange,errorsForm}: FormProps) => {
  // The element is destructured to get the values of the activity.
  const {code, name, content, date_init, date_end, hour_init, hour_end, management_file_public} =
    element;
  // The eventContext is used to get the event data.
  const event = useContext(eventContext);

  //console.log('errorsForm ==>',errorsForm);
  const showError = (error: string, text: string) => {
    const showError = errorsForm.includes(error)
    if (showError) {
      return text
    }
    return;
  }
  return (
    <>
      <div className='row p-4'>
        <FormElemenWrapper label='Nombre de la actividad' required>
          <input
            id={`name-${code}`}
            className={'form-control input-document ' + (errorsForm.includes('name') ? "is-invalid" : " ")}
            type='text'
            value={name}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'name', e.target.value)}
          />
           <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {showError('name', 'Nombre de la actividad es requerido')}
            </span>
          </div>
        </FormElemenWrapper>
        <FormElemenWrapper label='Descripción de la actividad'>
          <textarea
            id={`content-${code}`}
            className='form-control input-document'
            value={content}
            disabled={formDisabled}
            onChange={(e) => onChange(code, 'content', e.target.value)}
          />
        </FormElemenWrapper>
        <div className='col-12'>
          <FormElemenWrapper label=''>
            <InputUploadFiles
              id={`image-${code}`}
              required={false}
              formDisabled={formDisabled}
              file={management_file_public ? management_file_public[0] : null}
              handleFunction={(value: FileUploaded | null) =>
                onChange(code, 'management_file_public', value ? [value] : [])
              }
            />
          </FormElemenWrapper>
        </div>
        <FormElemenWrapper label='Fecha de inicio' required>
          <DatePickerSimple
            id={`date_init-${code}`}
            handleChange={(value: string) => onChange(code, 'date_init', value)}
            formDisabled={formDisabled}
            dateValue={date_init ? date_init : event?.start_date}
            minDate={event?.start_date}
            maxDate={date_end ? date_end : event?.end_date}
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Hora de inicio' required>
          <TimePicker
            id={`hour_init-${code}`}
            handleChange={(value: string) => onChange(code, 'hour_init', value)}
            formDisabled={formDisabled}
            timeValue={hour_init}
            maxTime={hour_end}
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Fecha de fin' required>
          <DatePickerSimple
            id={`date_end-${code}`}
            handleChange={(value: string) => onChange(code, 'date_end', value)}
            formDisabled={formDisabled}
            dateValue={date_end ? date_end : event?.start_date}
            minDate={date_init ? date_init : event?.start_date}
            maxDate={event?.end_date}
          />
        </FormElemenWrapper>
        <FormElemenWrapper label='Hora de fin' required>
          <TimePicker
            id={`hour_end-${code}`}
            handleChange={(value: string) => onChange(code, 'hour_end', value)}
            formDisabled={formDisabled}
            timeValue={hour_end}
            minTime={hour_init}
          />
        </FormElemenWrapper>
      </div>
    </>
  )
})

export default ActivityForm
